const tokenData = [
    {
      name: 'DogeADA',
      description: 'The meme that conquered both Doge and ADA universes!',
      startsAt: '2024-09-20T10:00:00Z',
      endsAt: '2024-10-20T10:00:00Z',
      price: '',
      maxPerTx: '500',
      softCap: '50000',
      hardCap: '100,000',
      totalSupply: '1,000,000,000',
      presale: '50',
      liquidity: '30',
      advisors: '10',
      team: '5',
      communityFunds: '5',
      status: 'Upcoming',
      fundsLocked: true,
      lpLocked: true,
      unlockTime: '2024-12-20T10:00:00Z',
      lpBurned: false,
      profilePicture: 'Dogue.png',
      wallet: 'addr1qxpdvha62h45lhuwqr30mqmvjz8y53yrl2pgmjwfu5k6z2txex0fxzn0tkhk8z4rw9syp98ptxf28aj9jkngggy7kxmscjgxmu',
      twitterLink: 'https://twitter.com/dogeada',
      discordLink: 'https://discord.gg/dogeada',
      websiteLink: 'https://dogeada.com',
    },
    {
      name: 'CardanoKitty',
      description: 'Meow! The cutest token on the blockchain!',
      startsAt: '2024-08-25T08:00:00Z',
      endsAt: '2024-08-25T08:00:00Z',
      price: '0.00002',
      maxPerTx: '400',
      softCap: '30000',
      hardCap: '90000',
      totalSupply: '750,000,000',
      presale: '40',
      liquidity: '35',
      advisors: '15',
      team: '5',
      communityFunds: '5',
      status: 'Upcoming',
      fundsLocked: true,
      lpLocked: true,
      unlockTime: '2025-01-10T08:00:00Z',
      lpBurned: false,
      profilePicture: 'cat.png',
      wallet: 'addr1q8uly9example2',
      twitterLink: 'https://twitter.com/cardanokitty',
      discordLink: 'https://discord.gg/cardanokitty',
      websiteLink: 'https://cardanokitty.com',
    },
    {
      name: 'PepeADA',
      description: 'Pepe joins the Cardano revolution with this iconic meme token.',
      startsAt: '2024-09-18T12:00:00Z',
      endsAt: '2024-10-18T12:00:00Z',
      price: '0.00005',
      maxPerTx: '300',
      softCap: '40000',
      hardCap: '120000',
      totalSupply: '500,000,000',
      presale: '45',
      liquidity: '40',
      advisors: '5',
      team: '5',
      communityFunds: '5',
      status: 'Live',
      fundsLocked: true,
      lpLocked: true,
      unlockTime: '2024-11-30T12:00:00Z',
      lpBurned: false,
      profilePicture: 'pepeada.png',
      wallet: 'addr1q8uly9example3',
      twitterLink: 'https://twitter.com/pepeada',
      discordLink: 'https://discord.gg/pepeada',
      websiteLink: 'https://pepeada.com',
    },
    {
      name: 'ADAWhale',
      description: 'Whales love ADA, and this meme token is no exception!',
      startsAt: '2024-09-30T09:00:00Z',
      endsAt: '2024-10-30T09:00:00Z',
      price: '0.0001',
      maxPerTx: '100',
      softCap: '100000',
      hardCap: '200000',
      totalSupply: '1,500,000,000',
      presale: '50',
      liquidity: '30',
      advisors: '10',
      team: '5',
      communityFunds: '5',
      status: 'Upcoming',
      fundsLocked: false,
      lpLocked: true,
      unlockTime: '2024-12-30T09:00:00Z',
      lpBurned: true,
      profilePicture: 'adawhale.png',
      wallet: 'addr1q8uly9example4',
      twitterLink: 'https://twitter.com/adawhale',
      discordLink: 'https://discord.gg/adawhale',
      websiteLink: 'https://adawhale.com',
    },
    {
      name: 'ShibaCardano',
      description: 'The shiba revolution continues on the Cardano blockchain!',
      startsAt: '2024-09-22T11:00:00Z',
      endsAt: '2024-10-22T11:00:00Z',
      price: '0.00003',
      maxPerTx: '600',
      softCap: '60000',
      hardCap: '150000',
      totalSupply: '2,000,000,000',
      presale: '40',
      liquidity: '35',
      advisors: '10',
      team: '5',
      communityFunds: '10',
      status: 'Live',
      fundsLocked: false,
      lpLocked: false,
      unlockTime: null, // Not applicable as LP is not locked
      lpBurned: false,
      profilePicture: 'shibacardano.png',
      wallet: 'addr1q8uly9example5',
      twitterLink: 'https://twitter.com/shibacardano',
      discordLink: 'https://discord.gg/shibacardano',
      websiteLink: 'https://shibacardano.com',
    },
    {
      name: 'FlokiADA',
      description: 'Floki goes full Cardano in this powerful meme token!',
      startsAt: '2024-09-1T13:00:00Z',
      endsAt: '2024-10-19T13:00:00Z',
      price: '0.00007',
      maxPerTx: '200',
      softCap: '80000',
      hardCap: '200000',
      totalSupply: '1,250,000,000',
      presale: '45',
      liquidity: '30',
      advisors: '10',
      team: '10',
      communityFunds: '5',
      status: 'Upcoming',
      fundsLocked: true,
      lpLocked: true,
      unlockTime: '2024-12-19T13:00:00Z',
      lpBurned: true,
      profilePicture: 'ermo.jpg',
      wallet: 'addr1q8uly9example6',
      twitterLink: 'https://twitter.com/flokiada',
      discordLink: 'https://discord.gg/flokiada',
      websiteLink: 'https://flokiada.com',
    },
  ];
  
  export default tokenData;
  