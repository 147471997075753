import React, { useState, useEffect } from 'react';

// Function to fetch the current time from World Time API
const fetchCurrentTime = async () => {
  try {
    const response = await fetch('http://worldtimeapi.org/api/ip');
    const data = await response.json();
    console.log("Fetched UTC Time:", data.utc_datetime); // Debugging log for fetched time
    return new Date(data.utc_datetime); // Return the UTC time
  } catch (error) {
    console.error('Error fetching current time:', error);
    return new Date(); // Fallback to local time if API fails
  }
};

// Function to calculate time left until the start date in UTC
const calculateTimeLeft = (currentTime, startTime) => {
  const startDate = new Date(startTime).getTime(); // Convert start time to UTC milliseconds
  const currentTimestamp = currentTime.getTime(); // Current UTC time in milliseconds

  const difference = startDate - currentTimestamp; // Time difference in milliseconds

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  } else {
    return null; // Countdown is complete
  }
};

const Timer = ({ startTime }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    // Fetch current time from World Time API on initial load
    const fetchTime = async () => {
      const fetchedTime = await fetchCurrentTime();
      setCurrentTime(fetchedTime);
      const calculatedTimeLeft = calculateTimeLeft(fetchedTime, startTime);
      setTimeLeft(calculatedTimeLeft);
    };

    fetchTime();

    const timerInterval = setInterval(() => {
      setCurrentTime((prevTime) => {
        const newTime = new Date(prevTime.getTime() + 1000); // Increment by 1 second
        const calculatedTimeLeft = calculateTimeLeft(newTime, startTime);
        setTimeLeft(calculatedTimeLeft);

        if (!calculatedTimeLeft) {
          clearInterval(timerInterval); // Stop interval if countdown is over
        }

        return newTime;
      });
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup interval on unmount
  }, [startTime]);

  if (!timeLeft) {
    console.log('No time left or countdown expired'); // Debugging line
    return null; // Return nothing if countdown is complete
  }

  return (
    <div style={styledTimer.timerContainer}>
      <div style={styledTimer.timeNumber}>{String(timeLeft.days).padStart(2, '0')}</div>
      <div style={styledTimer.separator}>:</div>
      <div style={styledTimer.timeNumber}>{String(timeLeft.hours).padStart(2, '0')}</div>
      <div style={styledTimer.separator}>:</div>
      <div style={styledTimer.timeNumber}>{String(timeLeft.minutes).padStart(2, '0')}</div>
      <div style={styledTimer.separator}>:</div>
      <div style={styledTimer.timeNumber}>{String(timeLeft.seconds).padStart(2, '0')}</div>
    </div>
  );
};

// New styling to make the timer more compact
const styledTimer = {
  timerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: "'Schoolbell', cursive",
    color: '#FF4500',
    fontSize: '0.8rem', // Smaller font size for the timer numbers
    marginTop: '5px',
    textAlign: 'center',
  },
  timeNumber: {
    fontSize: '1.2rem', // Reduced size for the numbers
    fontWeight: 'bold',
    textShadow: '2px 2px 0px #000', // Black shadow for cartoony outline effect
    color: '#FFFFFF', // White number with shadow for contrast
    margin: '0 3px', // Slightly smaller space between the numbers
    padding: '3px',
    borderRadius: '6px',
    backgroundColor: '#FF4500', // Background color behind the time numbers for more visibility
    boxShadow: '2px 2px 0px #000', // Cartoony effect with shadow
  },
  separator: {
    fontSize: '1.2rem', // Reduced separator size
    color: '#FFD700', // Gold separator for cartoonish style
    margin: '0 4px', // Slightly smaller space around the separator
  },
};

export default Timer;
