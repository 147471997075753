import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Typography, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Avatar, Select, MenuItem, FormControl, Checkbox, ListItemText, IconButton, LinearProgress, Snackbar, TextField } from '@mui/material';
import { styled } from '@mui/system';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Lucid, Blockfrost } from 'lucid-cardano';
import Timer from './Timer'; // Import Timer component
import tokenData from './Tokens'; // Import tokenData

// Register chart components
ChartJS.register(ArcElement, Tooltip, Legend);

const CartoonyTitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '40px',
});

const CartoonyLetter = styled(Typography)(({ theme }) => ({
  fontSize: '6rem',
  fontFamily: "'Schoolbell', cursive",
  color: '#000',
  textShadow: '3px 3px 0px #FFF, 6px 6px 0px #FF4500, 9px 9px 0px #000',
  transition: 'transform 0.3s ease-in-out',
  display: 'inline-block',
  '&:hover': {
    transform: 'scale(1.2)',
    textShadow: '6px 6px 0px #FFF, 9px 9px 0px #000, 12px 12px 0px #FF4500',
  },
  '@media (max-width: 600px)': {
    fontSize: '3rem',
  },
}));

const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: '20px',
  borderRadius: '15px',
  marginTop: '10px',
  backgroundColor: '#E0E0E0',
  boxShadow: '4px 4px 0px #000',
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(90deg, #FF4500 0%, #FFD700 100%)',
    borderRadius: '15px',
    transition: 'width 0.5s ease-out',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF4500',
  color: '#FFFFFF',
  border: '3px solid black',
  fontFamily: "'Schoolbell', cursive",
  transition: 'transform 0.3s ease-in-out',
  boxShadow: '4px 4px 0px #000',
  '&:hover': {
    backgroundColor: '#FFD700',
    color: '#000',
    transform: 'scale(1.1)',
    boxShadow: '6px 6px 0px #000',
  },
  '&.Mui-disabled': {
    backgroundColor: '#CCC',
    color: '#FFF',
    border: '3px solid #999',
  },
}));

const CartoonySelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '3px solid black',
  color: '#000000',
  fontFamily: "'Schoolbell', cursive",
  borderRadius: '10px',
  boxShadow: '4px 4px 0px #000',
  '& .MuiSelect-select': {
    padding: '10px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover': {
    backgroundColor: '#FFD700',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: "'Schoolbell', cursive",
  color: '#000000',
  boxShadow: '3px 3px 0px #000',
  '&:hover': {
    backgroundColor: '#FFD700',
  },
}));

const HoverCard = styled(Card)(({ theme }) => ({
  margin: '10px',
  borderRadius: '10px',
  border: `3px solid black`,
  transition: 'transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  width: '100%',
  maxWidth: '350px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  boxShadow: '6px 6px 0px #000',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '8px 8px 0px #000',
  },
  '@media (max-width: 600px)': {
    margin: '0 auto',
  },
}));

const CartoonyDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: '3px solid black',
    borderRadius: '15px',
    boxShadow: '6px 6px 0px #000',
    transform: 'scale(1.05)',
    transition: 'transform 0.3s ease-in-out',
    fontFamily: "'Schoolbell', cursive",
    width: '95%',
    maxWidth: '600px',
    overflow: 'hidden',
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 'bold',
    color: '#000',
    textShadow: '3px 3px 0px #FFF, 3px 3px 0px #FF4500',
  },
  '& .MuiDialogContent-root': {
    padding: '20px',
  },
}));

const CartoonyAvatar = styled(Avatar)(({ theme }) => ({
  width: '60px',
  height: '60px',
  border: '3px solid black',
  boxShadow: '4px 4px 0px #000',
  marginBottom: '20px',
}));

const LikeButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF4500',
  color: '#FFF',
  border: '2px solid black',
  fontFamily: "'Schoolbell', cursive",
  boxShadow: '3px 3px 0px #000',
  padding: '4px 6px',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: '#FFD700',
    transform: 'scale(1.1)',
  },
}));

const DislikeButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF4500',
  color: '#FFF',
  border: '2px solid black',
  fontFamily: "'Schoolbell', cursive",
  boxShadow: '3px 3px 0px #000',
  padding: '4px 6px',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: '#FFD700',
    transform: 'scale(1.1)',
  },
}));

const CommentButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF4500',
  color: '#FFF',
  border: '3px solid black',
  fontFamily: "'Schoolbell', cursive",
  boxShadow: '4px 4px 0px #000',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: '#FFD700',
    transform: 'scale(1.1)',
  },
}));

const LikeDislikeBox = styled('div')({
  backgroundColor: '#FFF',
  border: '2px solid #000',
  boxShadow: '4px 4px 0px #000',
  padding: '4px 8px',
  marginBottom: '5px',
  marginTop: '5px',
  fontFamily: "'Schoolbell', cursive",
  fontSize: '0.9rem',
  color: '#000',
  borderRadius: '10px',
});

const CommentBox = styled('div')({
  backgroundColor: '#FFF',
  border: '2px solid #000',
  boxShadow: '4px 4px 0px #000',
  padding: '10px',
  marginTop: '10px',
  fontFamily: "'Schoolbell', cursive",
  borderRadius: '10px',
  color: '#000',
});

const Footer = styled('footer')({
  padding: '20px',
  backgroundColor: 'rgba(51, 51, 51, 0.8)',
  color: '#FFFFFF',
  width: '100%',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '15px',
  fontFamily: "'Schoolbell', cursive",
  zIndex: 1000,
  borderTop: '3px solid black',
  boxShadow: '0 -4px 0px #000',
});

const StatusCircle = styled('div')(({ color }) => ({
  width: '20px',
  height: '20px',
  backgroundColor: color,
  borderRadius: '50%',
  boxShadow: '3px 3px 0px #000',
  border: '2px solid black',
  marginRight: '10px',
}));

const getStatusColor = (status) => {
  switch (status) {
    case 'Live':
      return 'green';
    case 'Upcoming':
      return 'yellow';
    case 'Closed':
      return 'red';
    default:
      return 'gray';
  }
};

const convertToLocalTime = (timeString) => {
  if (!timeString) return 'N/A';

  const date = new Date(timeString);

  if (isNaN(date.getTime())) {
    console.error('Invalid time value:', timeString);
    return 'Invalid Date';
  }

  return new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }).format(date);
};

const fetchBalance = async (walletAddress) => {
  const blockfrostApiKey = 'mainnetKsDvlA00TQW8E0FVTYIto5WWz93lS5Xe';
  const baseUrl = 'https://cardano-mainnet.blockfrost.io/api/v0/';

  try {
    const response = await fetch(`${baseUrl}addresses/${walletAddress}`, {
      headers: { project_id: blockfrostApiKey },
    });
    const data = await response.json();
    return data.amount ? data.amount[0].quantity / 1000000 : 0;
  } catch (error) {
    console.error('Error fetching balance:', error);
    return 0;
  }
};

const connectWallet = async (walletKey, setLucid, setWalletAddress) => {
  if (!walletKey) {
    console.error('No wallet selected');
    return;
  }

  try {
    const api = await window.cardano[walletKey.toLowerCase()].enable();
    const lucidInstance = await Lucid.new(
      new Blockfrost('https://cardano-mainnet.blockfrost.io/api/v0', 'mainnetKsDvlA00TQW8E0FVTYIto5WWz93lS5Xe')
    );
    lucidInstance.selectWallet(api);

    const address = await lucidInstance.wallet.address();
    setLucid(lucidInstance);
    setWalletAddress(address);
  } catch (error) {
    console.error('Failed to connect wallet:', error);
  }
};

const StyledPieChart = ({ data, onPieClick }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          font: {
            family: "'Schoolbell', cursive",
            size: 16,
            weight: 'bold',
          },
          color: '#000',
        },
      },
      tooltip: {
        titleFont: {
          family: "'Schoolbell', cursive",
          size: 14,
          weight: 'bold',
        },
        bodyFont: {
          family: "'Schoolbell', cursive",
          size: 12,
          weight: 'bold',
        },
        backgroundColor: '#000',
        borderColor: '#000',
        borderWidth: 2,
        displayColors: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 4,
        borderColor: '#000',
        hoverBorderWidth: 6,
        hoverOffset: 20,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const { index } = elements[0];
        const label = data.labels[index];
        const value = data.datasets[0].data[index];
        const total = data.datasets[0].data.reduce((acc, val) => acc + val, 0);
        const percentage = ((value / total) * 100).toFixed(2);

        onPieClick({ label, percentage });
      }
    },
  };

  return <Pie data={data} options={options} />;
};

const determineStatus = (startDate, endDate, balance, hardCap) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (balance >= hardCap || now > end) {
    return 'Closed';
  } else if (now >= start && now <= end) {
    return 'Live';
  } else if (now < start) {
    return 'Upcoming';
  }

  return 'Unknown';
};

const calculateTokenPrice = (totalSupply, presale, hardCap) => {
  if (!totalSupply || !hardCap) return 0;
  const presaleTokens = (presale / 100) * totalSupply;
  const price = hardCap / presaleTokens;
  return parseFloat(price.toFixed(8)).toString().replace(/\.?0+$/, '');
};

const App = () => {
  const [open, setOpen] = useState(false);
  const [buyOpen, setBuyOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState({});
  const [walletAddress, setWalletAddress] = useState(null);
  const [lucid, setLucid] = useState(null);
  const [statusFilter, setStatusFilter] = useState(['Live', 'Upcoming', 'Closed']);
  const [availableWallets, setAvailableWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState('');
  const [tokenBalances, setTokenBalances] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [buyAmount, setBuyAmount] = useState('');
  const [clickedSegment, setClickedSegment] = useState(null);
  const [likes, setLikes] = useState({});
  const [dislikes, setDislikes] = useState({});
  const [comments, setComments] = useState({});
  const [commentOpen, setCommentOpen] = useState(false);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    if (window.cardano) {
      const wallets = Object.keys(window.cardano).filter((key) => window.cardano[key].enable);
      const capitalizedWallets = wallets.map(wallet => wallet.charAt(0).toUpperCase() + wallet.slice(1));
      setAvailableWallets(capitalizedWallets);
    }
  }, []);

  useEffect(() => {
    const updateTokenBalances = async () => {
      const balances = {};
      for (const token of tokenData) {
        const balance = await fetchBalance(token.wallet);
        balances[token.wallet] = balance;
      }
      setTokenBalances(balances);
    };

    updateTokenBalances();
  }, []);

  const handlePieClick = ({ label, percentage }) => {
    setClickedSegment({ label, percentage });
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setStatusFilter(value.length === 0 ? ['Live', 'Upcoming', 'Closed'] : value);
  };

  const handleClickOpen = (tokenDetails) => {
    setSelectedToken(tokenDetails);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBuyOpen = (tokenDetails) => {
    setSelectedToken(tokenDetails);
    setBuyOpen(true);
  };

  const handleBuyClose = () => {
    setBuyOpen(false);
    setBuyAmount('');
  };

  const handleBuyAmountChange = (event) => {
    const value = event.target.value;
    const maxPerWallet = parseFloat(selectedToken.maxPerTx);
    if (value <= maxPerWallet) {
      setBuyAmount(value);
    } else {
      setBuyAmount(maxPerWallet); // Cap at Max Per Wallet
    }
  };

  const handleMaxClick = () => {
    setBuyAmount(selectedToken.maxPerTx);
  };

  const handleLike = (token) => {
    setLikes((prev) => ({
      ...prev,
      [token.name]: (prev[token.name] || 0) + 1,
    }));
  };

  const handleDislike = (token) => {
    setDislikes((prev) => ({
      ...prev,
      [token.name]: (prev[token.name] || 0) + 1,
    }));
  };

  const handleCommentOpen = (tokenDetails) => {
    setSelectedToken(tokenDetails);
    setCommentOpen(true);
  };

  const handleCommentClose = () => {
    setCommentOpen(false);
    setNewComment('');
  };

  const handleCommentSubmit = () => {
    if (newComment.trim() === '' || newComment.length > 12) return;

    setComments((prev) => ({
      ...prev,
      [selectedToken.name]: [
        ...(prev[selectedToken.name] || []),
        { comment: newComment, wallet: walletAddress },
      ],
    }));
    setNewComment(''); // Clear the input
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const calculateProgress = (balance, hardCap) => {
    if (hardCap === 0) return 0;
    return Math.min((balance / hardCap) * 100, 100);
  };

  const displayHardCap = (hardCap) => `${(hardCap ? hardCap.toLocaleString() : '0')} ₳`; // Updated formatting for main card

  const handleBuy = async () => {
    if (!lucid || !walletAddress) {
      alert('Please connect a wallet first!');
      return;
    }

    try {
      const lovelaceAmount = buyAmount * 1000000; // Convert ADA to Lovelace
      const tx = await lucid
        .newTx()
        .payToAddress(selectedToken.wallet, { lovelace: lovelaceAmount })
        .complete();

      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      console.log('Transaction submitted with txHash:', txHash);

      handleSnackbarOpen(); // Show snackbar
      handleBuyClose();
    } catch (error) {
      console.error('Error during transaction:', error);
    }
  };

  const filteredTokens = tokenData
    .map((token) => ({
      ...token,
      status: determineStatus(token.startsAt, token.endsAt, tokenBalances[token.wallet], parseInt(token.hardCap?.replace(/,/g, '') || '0')),
      timerEnd: new Date(token.status === 'Live' ? token.endsAt : token.startsAt),
    }))
    .filter((token) => statusFilter.includes(token.status))
    .sort((a, b) => {
      if (a.status === 'Live' && b.status !== 'Live') return -1;
      if (b.status === 'Live' && a.status !== 'Live') return 1;
      if (a.status === 'Upcoming' && b.status !== 'Upcoming') return -1;
      if (b.status === 'Upcoming' && a.status !== 'Upcoming') return 1;
      const aDate = new Date(a.timerEnd); // Timer end date for sorting
      const bDate = new Date(b.timerEnd);
      return aDate - bDate;
    });

  return (
    <div
      style={{
        padding: '20px',
        minHeight: '100vh',
        backgroundImage: `url(${process.env.PUBLIC_URL + '/Background.png'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        fontFamily: "'Schoolbell', cursive",
        position: 'relative',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '120px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 0',
          flexWrap: 'wrap',
        }}
      >
        {walletAddress ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography variant="body1" style={{ color: '#FFFFFF', textShadow: '1px 1px 2px black', fontSize: '12px' }}>
              Connected: {walletAddress.slice(0, 8)}...{walletAddress.slice(-4)}
            </Typography>
            <StyledButton onClick={() => setWalletAddress(null)}>Disconnect</StyledButton>
          </div>
        ) : (
          <FormControl variant="outlined" size="small" style={{ minWidth: 150 }}>
            <CartoonySelect value={selectedWallet} onChange={(e) => connectWallet(e.target.value, setLucid, setWalletAddress)} displayEmpty>
              <MenuItem value="" disabled>
                Connect Wallet
              </MenuItem>
              {availableWallets.map((wallet) => (
                <StyledMenuItem key={wallet} value={wallet}>
                  {wallet}
                </StyledMenuItem>
              ))}
            </CartoonySelect>
          </FormControl>
        )}

        <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
          <CartoonySelect
            multiple
            value={statusFilter}
            onChange={handleFilterChange}
            renderValue={(selected) => selected.join(', ')}
          >
            {['Live', 'Upcoming', 'Closed'].map((status) => (
              <StyledMenuItem key={status} value={status}>
                <Checkbox checked={statusFilter.includes(status)} />
                <ListItemText primary={status} />
              </StyledMenuItem>
            ))}
          </CartoonySelect>
        </FormControl>
      </div>

      <CartoonyTitleWrapper>
        {'ErmoLaunch'.split('').map((letter, index) => (
          <CartoonyLetter key={index}>
            {letter}
          </CartoonyLetter>
        ))}
      </CartoonyTitleWrapper>

      <Grid container spacing={2} justifyContent="center">
        {filteredTokens.map((token, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <HoverCard>
              {/* Like/Dislike display moved to top left */}
              <LikeDislikeBox style={{ position: 'absolute', top: '10px', left: '10px' }}>
                {likes[token.name] || 0} / {dislikes[token.name] || 0}
              </LikeDislikeBox>
              {/* Like/Dislike buttons on the right top */}
              <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '5px' }}>
                <LikeButton onClick={() => handleLike(token)}><ThumbUpIcon /></LikeButton>
                <DislikeButton onClick={() => handleDislike(token)}><ThumbDownIcon /></DislikeButton>
              </div>
              <Avatar
                alt="Profile Picture"
                src={`${process.env.PUBLIC_URL}/${token.profilePicture || 'ermo.jpg'}`} // Profile picture from public folder
                sx={{
                  width: 60,
                  height: 60,
                  marginBottom: '10px',
                  border: '2px solid #000',
                  position: 'relative',
                  top: '5px',
                }}
              />
              <CardContent>
                <Typography variant="h5" style={{ fontWeight: 700, color: '#000000', fontFamily: "'Schoolbell', cursive", fontSize: '1.2rem', textAlign: 'center' }}>
                  {token.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive", fontSize: '0.9rem', textAlign: 'center', overflowWrap: 'break-word' }}>
                  {token.description}
                </Typography>

                <StyledProgressBar variant="determinate" value={calculateProgress(tokenBalances[token.wallet], parseInt(token.hardCap?.replace(/,/g, '') || '0'))} />
                <Typography variant="body2" style={{ color: '#000000', fontFamily: "'Schoolbell', cursive", marginTop: '5px', textAlign: 'center' }}>
                  {`${Math.floor(tokenBalances[token.wallet] || 0)} / ${displayHardCap(parseInt(token.hardCap?.replace(/,/g, '') || '0'))}`}
                </Typography>

                <Timer startTime={token.timerEnd.toISOString()} /> {/* Use timerEnd (Ends at for live tokens) */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                  <StatusCircle color={getStatusColor(token.status)} />
                  <Typography variant="body2" style={{ color: '#000000', fontFamily: "'Schoolbell', cursive", fontWeight: 'bold', marginLeft: '5px' }}>
                    {token.status}
                  </Typography>
                </div>
              </CardContent>
              <CardActions style={{ justifyContent: 'space-between', padding: '16px' }}>
                <StyledButton size="small" variant="contained" onClick={() => handleClickOpen(token)}>
                  Details
                </StyledButton>
                <CommentButton size="small" variant="contained" onClick={() => handleCommentOpen(token)} startIcon={<CommentIcon />}>
                  Comment
                </CommentButton>
                <StyledButton size="small" variant="contained" onClick={() => handleBuyOpen(token)} disabled={token.status === 'Closed'}>
                  Buy
                </StyledButton>
              </CardActions>
            </HoverCard>
          </Grid>
        ))}
      </Grid>

      {/* Buy Popup */}
      <CartoonyDialog open={buyOpen} onClose={handleBuyClose} TransitionComponent={Slide} keepMounted>
        <DialogTitle>{selectedToken.name} - Buy Tokens</DialogTitle>
        <DialogContent>
          <CartoonyAvatar alt="Profile Picture" src={`${process.env.PUBLIC_URL}/${selectedToken.profilePicture || 'ermo.jpg'}`} />
          <Typography variant="body1" style={{ color: '#000000', fontFamily: "'Schoolbell', cursive", marginBottom: '10px' }}>
            <strong>Price:</strong> {calculateTokenPrice(parseInt(selectedToken.totalSupply?.replace(/,/g, '') || '0'), selectedToken.presale, parseInt(selectedToken.hardCap?.replace(/,/g, '') || '0'))} ₳
          </Typography>
          <TextField
            label="Enter Amount"
            type="number"
            fullWidth
            value={buyAmount}
            onChange={handleBuyAmountChange}
            inputProps={{ step: 'any', inputMode: 'decimal', pattern: '[0-9]*', min: '0' }}
            style={{ marginBottom: '10px' }}
          />
          <StyledButton onClick={handleMaxClick}>Max</StyledButton>
          <Typography variant="body2" style={{ color: '#000000', fontFamily: "'Schoolbell', cursive", marginTop: '10px' }}>
            {buyAmount > 0 ? `You will get ${Math.floor(buyAmount / calculateTokenPrice(parseInt(selectedToken.totalSupply?.replace(/,/g, '') || '0'), selectedToken.presale, parseInt(selectedToken.hardCap?.replace(/,/g, '') || '0')))} ${selectedToken.name}` : ''}
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end', padding: '16px' }}>
          <StyledButton onClick={handleBuyClose}>Cancel</StyledButton>
          <StyledButton onClick={handleBuy}>Buy</StyledButton>
        </DialogActions>
      </CartoonyDialog>

      {/* View Details Popup */}
      <CartoonyDialog open={open} onClose={handleClose} TransitionComponent={Slide} keepMounted>
        <DialogTitle>{selectedToken.name} Details</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CartoonyAvatar alt="Profile Picture" src={`${process.env.PUBLIC_URL}/${selectedToken.profilePicture || 'ermo.jpg'}`} />
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexGrow: 1 }}>
              <IconButton href={selectedToken.twitterLink} target="_blank" rel="noopener noreferrer" color="primary">
                <TwitterIcon sx={{ color: '#000' }} />
              </IconButton>
              <IconButton href={selectedToken.discordLink} target="_blank" rel="noopener noreferrer" color="primary">
                <img src={`${process.env.PUBLIC_URL}/discord2.svg`} alt="Discord" style={{ width: '24px', height: '24px', filter: 'brightness(0)' }} />
              </IconButton>
              <IconButton href={selectedToken.websiteLink} target="_blank" rel="noopener noreferrer" color="primary">
                <LanguageIcon sx={{ color: '#000' }} />
              </IconButton>
            </div>
          </div>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Starts at:</strong> {convertToLocalTime(selectedToken.startsAt)}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Ends at:</strong> {convertToLocalTime(selectedToken.endsAt)}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Price:</strong> {calculateTokenPrice(parseInt(selectedToken.totalSupply?.replace(/,/g, '') || '0'), selectedToken.presale, parseInt(selectedToken.hardCap?.replace(/,/g, '') || '0'))} ₳
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Max Per wallet:</strong> {selectedToken.maxPerTx} ₳
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Soft cap:</strong> {selectedToken.softCap} ₳
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Hard cap:</strong> {displayHardCap(parseInt(selectedToken.hardCap?.replace(/,/g, '') || '0'))}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Total token supply:</strong> {selectedToken.totalSupply}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Presale:</strong> {selectedToken.presale}%
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Liquidity:</strong> {selectedToken.liquidity}%
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Advisors & Partners:</strong> {selectedToken.advisors}%
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Team:</strong> {selectedToken.team}%
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '10px', color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>Community funds:</strong> {selectedToken.communityFunds}%
          </Typography>
          <Typography variant="body1" style={{ color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>LP is locked:</strong> {selectedToken.lpLocked ? 'Yes' : 'No'}
          </Typography>
          {selectedToken.lpLocked && (
            <Typography variant="body1" style={{ color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
              <strong>Unlock time:</strong> {convertToLocalTime(selectedToken.unlockTime)}
            </Typography>
          )}
          <Typography variant="body1" style={{ color: '#000000', fontFamily: "'Schoolbell', cursive" }}>
            <strong>LP is burned:</strong> {selectedToken.lpBurned ? 'Yes' : 'No'}
          </Typography>
          <div style={{ marginTop: '20px' }}>
            <StyledPieChart
              data={{
                labels: ['Presale', 'Liquidity', 'Advisors & Partners', 'Team', 'Community Funds'],
                datasets: [
                  {
                    label: 'Token Distribution',
                    data: [selectedToken.presale, selectedToken.liquidity, selectedToken.advisors, selectedToken.team, selectedToken.communityFunds],
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
                  },
                ],
              }}
              onPieClick={handlePieClick}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end', padding: '16px' }}>
          <StyledButton onClick={handleClose}>Close</StyledButton>
        </DialogActions>
      </CartoonyDialog>

      {/* Comment Popup */}
      <CartoonyDialog open={commentOpen} onClose={handleCommentClose} TransitionComponent={Slide} keepMounted>
        <DialogTitle>{selectedToken.name} - Add Comment</DialogTitle>
        <DialogContent>
          <TextField
            label="Enter Comment"
            type="text"
            fullWidth
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            inputProps={{ maxLength: 12 }}
            style={{ marginBottom: '10px' }}
          />
          {comments[selectedToken.name] && comments[selectedToken.name].map((comment, idx) => (
            <CommentBox key={idx}>
              {`${comment.wallet.slice(0, 8)}...${comment.wallet.slice(-4)}: ${comment.comment}`}
            </CommentBox>
          ))}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end', padding: '16px' }}>
          <StyledButton onClick={handleCommentClose}>Cancel</StyledButton>
          <StyledButton onClick={handleCommentSubmit}>Send</StyledButton>
        </DialogActions>
      </CartoonyDialog>

      {clickedSegment && (
        <div
          style={{
            marginTop: '20px',
            padding: '10px',
            backgroundColor: '#FFF',
            border: '2px solid #000',
            fontFamily: "'Schoolbell', cursive",
            color: '#000',
            borderRadius: '10px',
            boxShadow: '4px 4px 0px #000',
          }}
        >
          <Typography variant="h6">
            You clicked on <strong>{clickedSegment.label}</strong>, which is <strong>{clickedSegment.percentage}%</strong> of the total.
          </Typography>
        </div>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="Transaction completed successfully!" />

      <Footer>
        <IconButton href="https://www.ermocoin.com/" target="_blank" rel="noopener noreferrer" color="primary">
          <LanguageIcon sx={{ color: '#FFF' }} />
        </IconButton>
        <IconButton href="https://x.com/ermocoin" target="_blank" rel="noopener noreferrer" color="primary">
          <TwitterIcon sx={{ color: '#FFF' }} />
        </IconButton>
        <IconButton href="https://discord.com/invite/YAHVKYTdYk" target="_blank" rel="noopener noreferrer" color="primary">
          <img src={`${process.env.PUBLIC_URL}/discord.svg`} alt="Discord" style={{ width: '24px', height: '24px' }} />
        </IconButton>
        <Typography variant="body2" style={{ color: '#FFF' }}>
          © 2024 ErmoCoin. All rights reserved.
        </Typography>
      </Footer>
    </div>
  );
};

export default App;
